import React, { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsmobile from './aws-exports';
import App from './App';
import { message } from 'antd';
  if(process.env.REACT_APP_WARNING_MESSAGE){
    message.warning(process.env.REACT_APP_WARNING_MESSAGE)
  }
Amplify.configure(awsmobile);
//Auth.signOut();
function SignIn() {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);
  
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => setUser(currentUser))
      .catch(() => console.log("Not signed in"));

    return unsubscribe;
  }, []);
    // TODO switch this for authentication once this is up and running
    if (!user) {/*return (
    <div className="App">
      {Auth.federatedSignIn()}
    </div>
    )*/ } else {
    return(
        <App user={user}/>
    );
  };
}

export default withAuthenticator(SignIn, {"hideSignUp":true});